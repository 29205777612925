import React, {useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {bookVisitLink} from "../constants";
import '../styles/components/book-button.css'


import {ResizeContext} from '../context/resize-context';
import {useIntersectObserve} from "../hooks";


export const BookButton = () => {
    const buttonRef = useRef();
    const entry = useIntersectObserve(buttonRef);
    const isVisible = entry?.isIntersecting;

    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    return (
        <>
            <div className="book-button-wrapper full-width-content" ref={buttonRef}>
                <button className={`book-button  ${isVisible && 'book-button-fixed'}`}
                        onClick={() => window.open(bookVisitLink)}>{t('common.book_visit')}</button>
            </div>
        </>
    )
};
