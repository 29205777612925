export const CosmetologyDeviceCard = ({caption, title, imageUrl, onClick}) => {
    return (
        <div onClick={onClick} className="cosmetology-device-card">
            <img className="cosmetology-device-card-img" src={imageUrl} alt={imageUrl}/>
            <div className="cosmetology-device-card-caption">{caption}</div>
            <div className="cosmetology-device-card-title">{title}</div>
        </div>
    );
};
export default CosmetologyDeviceCard;
