import React from 'react';
import '../../styles/components/privacy-policy.css'
import {Page} from "../../components/page";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";


export const Cookie = () => {
    const {t,i18n } = useTranslation();

    const currentLanguage = i18n.language;
    let policyText;
    if (currentLanguage === "pl") {
        policyText = (
            <>
                <h4>1. Informacja o plikach cookies</h4>
                <p>Serwis wykorzystuje pliki cookies.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Pliki cookies (tzw. &bdquo;ciasteczka&rdquo;) stanowią dane informatyczne, w szczeg&oacute;lności pliki tekstowe, kt&oacute;re przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z kt&oacute;rej pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest operator Serwisu.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Pliki cookies wykorzystywane są w następujących celach:</p>
                <p><br/></p>
                <p><br/></p>
                <p>utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki kt&oacute;rej Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;</p>
                <p><br/></p>
                <p>realizacji cel&oacute;w określonych w sekcji &bdquo;Istotne Techniki Marketingowe&rdquo; powyżej;</p>
                <p><br/></p>
                <p>W ramach Strony stosowane są dwa zasadnicze rodzaje plik&oacute;w cookies: &bdquo;sesyjne&rdquo; (session cookies) oraz &bdquo;stałe&rdquo; (persistent cookies). Cookies &bdquo;sesyjne&rdquo; są plikami tymczasowymi, kt&oacute;re przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). &bdquo;Stałe&rdquo; pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plik&oacute;w cookies lub do czasu ich usunięcia przez Użytkownika.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plik&oacute;w cookies w urządzeniu końcowym Użytkownika. Użytkownicy serwisu mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plik&oacute;w cookies. Możliwe jest także automatyczne blokowanie plik&oacute;w cookies. Szczeg&oacute;łowe informacje na ten temat zawiera pomoc lub dokumentacja przeglądarki internetowej.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Ograniczenia stosowania plik&oacute;w cookies mogą wpłynąć na niekt&oacute;re funkcjonalności dostępne na stronach internetowych Serwisu.</p>
                <p><br/></p>
                <p><br/></p>
                <p>Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być r&oacute;wnież przez wsp&oacute;łpracujące z operatorem Serwisu podmioty, w szczeg&oacute;lności dotyczy to firm Google (Google Inc. z siedzibą w USA), Facebook (Facebook Inc. z siedzibą w USA), Twitter (Twitter Inc. z siedzibą w USA).</p>
                <p><br/></p>
                <p><br/></p>
                <p><br/></p>
                <h4>2. Zarządzanie plikami cookies - jak w praktyce wyrażać i cofać zgodę?</h4>
                <p>Jeśli nie chcesz otrzymywać plik&oacute;w cookies, możesz zmienić ustawienia swojej przeglądarki. Zastrzegamy, że wyłączenie obsługi plik&oacute;w cookies niezbędnych dla proces&oacute;w uwierzytelniania, bezpieczeństwa, utrzymania preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie ze stron www.</p>
                <p><br/></p>
                <p><br/></p>
                <p>W celu zarządzania ustawieniami cookies wybierz z listy poniżej przeglądarkę internetową, kt&oacute;rej używasz i postępuj zgodnie z instrukcjami:</p>
                <p><br/></p>
                <p><br/></p>
                <p>Edge</p>
                <p>Internet Explorer</p>
                <p>Chrome</p>
                <p>Safari</p>
                <p>Firefox</p>
                <p>Opera</p>
                <p>Urządzenia mobilne:</p>
                <p><br/></p>
                <p>Android</p>
                <p>Safari (iOS)</p>
                <p>Windows Phone</p>
               <br/>
                <br/>
            </>
        );
    } else {
        policyText = (
            <>
                <h4>1. Information about cookies</h4>
                <p>The website uses cookies.</p>
                <br/>
                <br/>
                <p>Cookies (so-called &quot;cookies&quot;) are IT data, in particular text files, which are stored on
                    the Website User&apos;s end device and are intended for using the Website&apos;s websites. Cookies
                    usually contain the name of the website they come from, their storage time on the end device and a
                    unique number.</p>
                <br/>
                <br/>
                <p>The entity that places cookies on the Website User&apos;s end device and obtains access to them is
                    the Website operator.</p>
                <br/>
                <br/>
                <p>Cookies are used for the following purposes:</p>
                <br/>
                <br/>
                <p>maintaining the Website user&apos;s session (after logging in), thanks to which the user does not
                    have to re-enter the login and password on each subpage of the Website;</p>
                <p>achieving the objectives set out in the &quot;Substantial Marketing Techniques&quot; section
                    above;</p>
                <p>The Website uses two basic types of cookies: &quot;session cookies&quot; and &quot;persistent
                    cookies&quot;. &quot;Session&quot; cookies are temporary files that are stored on the User&apos;s
                    end device until logging out, leaving the website or turning off the software (web
                    browser). &quot;Permanent&quot; cookies are stored on the User&apos;s end device for the time
                    specified in the cookie parameters or until they are deleted by the User.</p>
                <br/>
                <br/>
                <p>Software for browsing websites (web browser) usually allows cookies to be stored on the User&apos;s
                    end device by default. Website users may change the settings in this regard. The web browser allows
                    you to delete cookies. It is also possible to automatically block cookies. Detailed information on
                    this subject can be found in the help or documentation of your web browser.</p>
                <br/>
                <br/>
                <p>Restrictions on the use of cookies may affect some of the functionalities available on the
                    Website.</p>
                <br/>
                <br/>
                <p>Cookies placed on the Website User&apos;s end device may also be used by entities cooperating with
                    the Website operator, in particular the following companies: Google (Google Inc. based in the USA),
                    Facebook (Facebook Inc. based in the USA), Twitter (Twitter Inc. based in the USA).</p>
                <br/>
                <br/>
                <h4>2. Cookies management &ndash; how to express and withdraw consent in practice?</h4>
                <p>If you do not want to receive cookies, you can change your browser settings. We reserve that
                    disabling the use of cookies necessary for authentication processes, security, maintaining user
                    preferences may make it difficult, and in extreme cases may prevent the use of websites.</p>
                <br/>
                <br/>
                <p>To manage cookie settings, select the web browser you are using from the list below and follow the
                    instructions:</p>
                <br/>
                <br/>
                <p>Edge</p>
                <p>Internet Explorer</p>
                <p>Chrome</p>
                <p>Safari</p>
                <p>Firefox</p>
                <p>Opera</p>
                <p>Mobile devices:</p>
                <br/>
                <br/>
                <p>Android</p>
                <p>Safari (iOS)</p>
                <p>Windows Phone</p>
                <br/>
                <br/>
            </>
        );
    }
    return (
        <Page>
            <Helmet>
                <title>{t('common.cookie')} | Klinika urody w Warszawie, Polsce</title>
            </Helmet>
            <h1 className="privacy-title">{t('common.cookie')}</h1>
            <div className="privacy-page opacity07">
                {policyText}
            </div>
        </Page>
    )
}