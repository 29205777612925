import React, {memo} from 'react';
import {Page} from "../../components/page";
import Banner from '../../shared/components/banner';
import servicePageBanner from '../../assets/banners/service-page-banner.jpg'
import servicePageBannerMobile from '../../assets/banners/service-page-banner-mobile.jpg'
import AllServices from './sections/all-services';
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet-async";

export const Services = memo(() => {
    const {t} = useTranslation();

    return (
    <Page>
        <Helmet>
            <title>{t('services.title')} | Klinika urody w Warszawie, Polsce</title>
        </Helmet>
        <Banner image={servicePageBanner}
                imageXs={servicePageBannerMobile}>
            {t('services.title')}
        </Banner>
        <AllServices></AllServices>
    </Page>

)

})