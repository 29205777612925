import Button from '../../../shared/components/button';
import ServiceList from '../../../shared/components/service-list';
import ServiceFilter from '../../../shared/components/service-filter';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import sliderArrowLeft from '../../../assets/icons/arrow-left.svg';
import sliderArrowRight from '../../../assets/icons/arrow-right.svg';
import {useNavigate} from 'react-router-dom';

import '../../../styles/components/services.css';
import {ResizeContext} from '../../../context/resize-context';
import useServiceManager from '../../../hooks/service.hook';
import {Slide} from 'react-slideshow-image';

import ServiceCard from '../../../shared/components/service-card';

export const Services = () => {
    const {t} = useTranslation();
    const {isTablet, isMobile} = useContext(ResizeContext);

    const navigate = useNavigate();
    const shortFilterCountView = 5;

    const {
        filters,
        filteredServices,
        activeFilter,
        setSelectedFilter,
        handlePrev,
        handleNext,
        startIndex
    } = useServiceManager({isTablet, isShortView: true});

    const [shortFilterList, setShortFilterList] = useState([]);

    useEffect(() => {
        if (isTablet) {
            setShortFilterList(filters);
            return;
        }
        const remainingCount = filters.length - shortFilterCountView;
        const count = shortFilterCountView - (filters?.length - startIndex);

        const filterLoopEnd = startIndex > remainingCount ? filters.slice(startIndex, filters.length) : [];
        const filterLoopStart = startIndex > remainingCount && count < shortFilterCountView ? filters?.slice(0, count) : filters?.slice(startIndex, startIndex + shortFilterCountView);

        setShortFilterList([...filterLoopEnd, ...filterLoopStart]);
    }, [startIndex, filters, isTablet]);

    const onFilterValueChange = (value) => {
        setSelectedFilter(value);
    };

    const onServiceCardClick = (key) => {
        navigate(`/service/${key}/`)
    };

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={sliderArrowLeft} alt="chevron"/>
    </div>;

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }

    return (
        <>
            <section className="main-services-section">
                <div className="service-section-title-block">
                    <a onClick={() => navigate('/services')}
                       className="section-title">
                        <h2 className="section-title">{t('services.title')}</h2>
                    </a>
                    <ServiceFilter filters={shortFilterList}
                                   activeFilter={activeFilter}
                                   onValueChange={onFilterValueChange}>
                    </ServiceFilter>

                    {!isTablet &&
                    <div className="row">
                        <button onClick={handlePrev} className="icon-button">
                            <img src={sliderArrowLeft}
                                 alt="slider-arrow-left.svg"
                                 className="prev"/>
                        </button>

                        <button onClick={handleNext} className="icon-button">
                            <img src={sliderArrowRight}
                                 alt="slider-arrow-right.svg"
                                 className="next"/>
                        </button>
                    </div>}
                </div>
                {!isMobile &&
                <ServiceList data={filteredServices.slice(0, 8)}
                             onServiceCardClick={onServiceCardClick}
                             selectedCategory={activeFilter}>
                </ServiceList>
                }
                {isMobile &&
                <div className="services-section-mobile">
                    <Slide autoplay={true} slidesToShow={1} {...properties}>
                        {filteredServices?.map((service, index) => (
                            <ServiceCard
                                onClick={() => onServiceCardClick(service.key)}
                                key={service.key || index}
                                caption={t(`services.${service.key}.title`)}
                                imageUrl={service.image}
                            />
                        ))}
                    </Slide>
                </div>
                }
                <div className="row">
                    <Button onClick={() => navigate('/services')}>
                        {t('services.see_all_btn')}
                    </Button>
                </div>

            </section>
            <div className="section-delimiter full-width-content"></div>
        </>
    )
}