import Button from '../../../shared/components/button';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ResizeContext } from '../../../context/resize-context';
import { Slide } from 'react-slideshow-image';
import CosmetologyDeviceCard from '../../../shared/components/cosmetology-device-card';
import { team } from '../../../shared/configs/team.config';
import sliderArrowLeft from '../../../assets/icons/arrow-left.svg';
import '../../../styles/components/team.css'
import TeamMemberCard from '../../../shared/components/team-member-card';
import TeamCard from "../../../shared/components/team-card";

const responsiveSettings = [
    {
        breakpoint: 991,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];
export const Team = () => {
    const {t} = useTranslation();
    const {width, isMobile} = useContext(ResizeContext);
    const [teamList, setTeamList] = useState(team.slice(0, 4));
    const onTeamCardClick = (key) => {
        navigate(`/team/${key}`)
    };
    const navigate = useNavigate();

    return (
        <section className="main-team-section">
            <div className="team-section-title-block">
                <a onClick={() => navigate('/team')}
                   className="section-title">
                    <h2 className="section-title">{t('team.title')}</h2>
                </a>
                {!isMobile &&
                    <p className="section-description">
                        {t('team.description')}
                    </p>
                }
            </div>
            <div className="team-container">
                <>
                    {teamList.map((member, index) => (
                        <TeamMemberCard key={member.key || index}
                                        onClick={() => onTeamCardClick(member.key)}
                                        imageUrl={member.imagesmall}
                                        name={t(`team.${member.key}.name`)}
                                        specialty={t(`team.${member.key}.specialty`)}
                        />
                    ))}
                </>
            </div>

            <div className="row">
                <Button onClick={() => navigate('/team')}>
                    {t('services.see_all_btn')}
                </Button>
            </div>
        </section>
    )
}