import React from 'react';
import { useTranslation } from "react-i18next";
import { Page } from "../../components/page";
import Banner from '../../shared/components/banner';
import devicesPageBanner from '../../assets/banners/devices-page-banner.jpg';
import devicesPageBannerMobile from '../../assets/banners/devices-page-banner-mobile.jpg';
import AllCosmetologyDevices from './sections/all-cosmetology-devices';
import {Helmet} from "react-helmet-async";

export const CosmetologyDevices = () => {
    const {t} = useTranslation();
    return (
        <Page>
            <Helmet>
                <title>{t('devices.title')} | Klinika urody w Warszawie, Polsce</title>
            </Helmet>
            <Banner image={devicesPageBanner}
                    imageXs={devicesPageBannerMobile}>
                {t('devices.title')}
            </Banner>
            <AllCosmetologyDevices></AllCosmetologyDevices>
        </Page>
    )
}