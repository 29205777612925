export const TeamCard = ({name, specialty, imageUrl, onClick}) => {
    return (
        // <div onClick={oncClick} className="team-card">
        //     <img className="team-card-img" src={imageUrl} alt={imageUrl}/>
        //     <div className="team-card-title">{title}</div>
        //     <div className="team-card-caption">{caption}</div>
        // </div>
    <div onClick={onClick} className="team-member-card">
        <img className="team-card-img" src={imageUrl} alt={imageUrl}/>
        <div className="team-member-card-name">{name}</div>
        <div className="team-member-card-specialty">{specialty}</div>
    </div>
    );
};
export default TeamCard;
