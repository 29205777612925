export const getResponsiveSettings = (imageWidth, imagesLength = 10, byOne = true) => [...Array(imagesLength)].map((_, index) => ({
    breakpoint: index === 0 ? 1 : index * imageWidth,
    settings: {
        slidesToShow: index === 0 ? 1 : index + 1,
        slidesToScroll: byOne ? 1 : index === 0 ? 1 : index + 1,
    }
})).reverse();

export const getAdaptiveSlidesCount = (breakpoint, imageWidth) => {
    const count = Math.ceil(breakpoint / imageWidth * 2) / 2;

    return {
        breakpoint: breakpoint,
        settings: {
            slidesToShow: count,
            slidesToScroll: count,
        }
    }
}

export const normalizeInstagramPostsResponse = (postsList) => {
    return postsList.map(({media_type, media_url, thumbnail_url}) => {
        if(media_type === 'VIDEO'){
            return thumbnail_url
        }
        return media_url
    });
};