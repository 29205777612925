import React from 'react';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import errorText from "../../assets/404.svg"
import '../../styles/components/404.css'
import {Helmet} from "react-helmet-async";

export const NotFound = () => {
    const {t} = useTranslation();
    return (
        <Page>
            <Helmet>
                <title>404| PAGE NOT FOUND | Klinika urody w Warszawie, Polsce</title>
            </Helmet>
            <div className='page-not-found col full-width-content'>
                <h5 className="title">oops!</h5>
                {/*<h2 className="title">404</h2>*/}
                <img src={errorText} alt="errorText"/>
                <p>PAGE NOT FOUND</p>
            </div>
        </Page>
    )
}