import React, {createContext, useLayoutEffect, useState} from 'react';

const resizeInitial = {
    width: window.innerWidth,
    height: window.innerHeight,
}
export const ResizeContext = createContext({
    ...resizeInitial,
    isMobile: false
});

export const ResizeContextProvider = ({ children }) => {
    const [size, setSize] = useState(resizeInitial);
    const [isDesktopHd, setIsDesktopHd] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    useLayoutEffect(() => {
        function updateSize() {
            setSize({width: window.innerWidth, height: window.innerHeight});
            setIsDesktopHd(window.innerWidth > 1920);
            setIsDesktop(window.innerWidth < 1441);
            setIsTablet(window.innerWidth < 991);
            setIsMobile(window.innerWidth < 600);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const values = { ...size, isMobile, isTablet, isDesktop }

    return <ResizeContext.Provider value={values}>{children}</ResizeContext.Provider>;
};
