import facialCleansing from '../../assets/services/facial-cleansing.jpg';
import facialCleansingBig from '../../assets/services/facial-cleansing-big.jpg';
import skinCareProcedures from '../../assets/services/skin-care-procedures.jpg';
import skinCareProceduresBig from '../../assets/services/skin-care-procedures-big.jpg';
import peeling from '../../assets/services/peeling.jpg';
import peelingBig from '../../assets/services/peeling-big.jpg';
import dermatologist from '../../assets/services/dermatologist.jpg';
import dermatologistBig from '../../assets/services/dermatologist-big.jpg';
import massage from '../../assets/services/massage.jpg';
import massageBig from '../../assets/services/massage-big.jpg';
import biorevitalization from '../../assets/services/biorevitalization.jpg';
import biorevitalizationBig from '../../assets/services/biorevitalization-big.jpg';
import blanching from '../../assets/services/blanching.jpg';
import blanchingBig from '../../assets/services/blanching-big.jpg';
import intimateRejuvenation from '../../assets/services/intimate-rejuvenation.jpg';
import intimateRejuvenationBig from '../../assets/services/intimate-rejuvenation-big.jpg';
import lipolysis from '../../assets/services/lipolysis.jpg';
import lipolysisBig from '../../assets/services/lipolysis-big.jpg';
import faceContouring from '../../assets/services/face-contouring.jpg';
import faceContouringBig from '../../assets/services/face-contouring-big.jpg';
import lipContouring from '../../assets/services/lip-contouring.jpg';
import lipContouringBig from '../../assets/services/lip-contouring-big.jpg';
import botulinumTherapy from '../../assets/services/botulinum-therapy.jpg';
import botulinumTherapyBig from '../../assets/services/botulinum-therapy-big.jpg';
import mesotherapy from '../../assets/services/mesotherapy.jpg';
import mesotherapyBig from '../../assets/services/mesotherapy-big.jpg';
import bodySculpting from '../../assets/services/body-sculpting.jpg';
import bodySculptingBig from '../../assets/services/body-sculpting-big.jpg';
import trichology from '../../assets/services/trichology.jpg';
import trichologyBig from '../../assets/services/trichology-big.jpg';
import plasmaTherapy from '../../assets/services/plasma-therapy.jpg';
import plasmaTherapyBig from '../../assets/services/plasma-therapy-big.jpg';
import LaserTherapy from '../../assets/services/laser-therapy.jpg';
import LaserTherapyBig from '../../assets/services/laser-therapy-big.jpg';
import podology from '../../assets/services/podology.jpg';
import podologyBig from '../../assets/services/podology-big.jpg';
import permanentMakeup from '../../assets/services/permanent-makeup.jpg';
import permanentMakeupBig from '../../assets/services/permanent-makeup-big.jpg';
import hyperhidrosis from '../../assets/services/hyperhidrosis.jpg';
import hyperhidrosisBig from '../../assets/services/hyperhidrosis-big.jpg';

export const categories = {
    ACNE: "acne",
    POST_ACNE: "post_acne",
    ANTI_AGES: "anti_ages",
    PIGMENTATION: "pigmentation",
    COUPEROS: "couperos",
    DRY_SKIN: "dry_skin",
    SKIN_REGENERATION: "skin_regeneration",
    CLEANSING: "cleansing",
    PUFFINESS: "puffiness",
    CELLULITE: "cellulite",
    DUNDRUFF: "dundruff",
    HAIR_LOSE: "hair_lose",
    HAIR_REMOVAL: "hair_removal",
}

export const services = [
    {
        id: 1,
        key: 'facial_cleansing',
        image: facialCleansing,
        imageBig: facialCleansingBig,
        categories: [
            categories.ACNE,
            categories.POST_ACNE,
            categories.ANTI_AGES,
            categories.PIGMENTATION,
            categories.CLEANSING
        ]
    },
    {
        id: 2,
        key: 'skin_care_procedure',
        image: skinCareProcedures,
        imageBig: skinCareProceduresBig,
        categories: [
            categories.ACNE,
            categories.POST_ACNE,
            categories.ANTI_AGES,
            categories.PIGMENTATION,
            categories.COUPEROS,
            categories.DRY_SKIN,
            categories.SKIN_REGENERATION,
        ]
    },
    {
        id: 3,
        key: 'peeling',
        image: peeling,
        imageBig: peelingBig,
        categories: [
            categories.ACNE,
            categories.POST_ACNE,
            categories.ANTI_AGES,
            categories.SKIN_REGENERATION,
            categories.CLEANSING
        ],
    },
    {
        id: 4,
        key: 'dermatologist',
        image: dermatologist,
        imageBig: dermatologistBig,
        categories: [
            categories.ACNE,
            categories.POST_ACNE,
            categories.PIGMENTATION,
            categories.COUPEROS,
            categories.DRY_SKIN,
            categories.SKIN_REGENERATION,
            categories.PUFFINESS
        ],
    },
    {
        id: 5,
        key: 'massage',
        image: massage,
        imageBig: massageBig,
        categories: [
            categories.POST_ACNE,
            categories.ANTI_AGES,
            categories.SKIN_REGENERATION,
            categories.PUFFINESS,
            categories.CELLULITE
        ],
    },
    {
        id: 6,
        key: 'biorevitalization',
        image: biorevitalization,
        imageBig: biorevitalizationBig,
        categories: [
            categories.POST_ACNE,
            categories.ANTI_AGES,
            categories.PIGMENTATION,
            categories.COUPEROS,
            categories.DRY_SKIN,
            categories.SKIN_REGENERATION,
            categories.HAIR_LOSE
        ],
    },
    {
        id: 7,
        key: 'blanching',
        image: blanching,
        imageBig: blanchingBig,
        categories: [
            categories.POST_ACNE,
            categories.ANTI_AGES,
            categories.SKIN_REGENERATION,
            categories.HAIR_LOSE
        ],
    },
    {
        id: 8,
        key: 'intimate_rejuvenation',
        image: intimateRejuvenation,
        imageBig: intimateRejuvenationBig,
        categories: [
            categories.ANTI_AGES,
            categories.PIGMENTATION,
        ],
    },
    {
        id: 9,
        key: 'lipolysis',
        image: lipolysis,
        imageBig: lipolysisBig,
        categories: [
            categories.ANTI_AGES,
            categories.CELLULITE,
        ],
    },
    {
        id: 10,
        key: 'face_contouring',
        image: faceContouring,
        imageBig: faceContouringBig,
        categories: [
            categories.ANTI_AGES,
        ],
    },
    {
        id: 11,
        key: 'lip_contouring',
        image: lipContouring,
        imageBig: lipContouringBig,
        categories: [
            categories.ANTI_AGES,
        ],
    },
    {
        id: 12,
        key: 'botulinum_therapy',
        image: botulinumTherapy,
        imageBig: botulinumTherapyBig,
        categories: [
            categories.ANTI_AGES,
            categories.SKIN_REGENERATION,
        ],
    },
    {
        id: 13,
        key: 'mesotherapy',
        image: mesotherapy,
        imageBig: mesotherapyBig,
        categories: [
            categories.ANTI_AGES,
            categories.SKIN_REGENERATION,
        ],
    },
    {
        id: 14,
        key: 'trichology',
        image: trichology,
        imageBig: trichologyBig,
        categories: [
            categories.DUNDRUFF,
            categories.HAIR_LOSE,
        ],
    },
    {
        id: 15,
        key: 'plasma_therapy',
        image: plasmaTherapy,
        imageBig: plasmaTherapyBig,
        categories: [
            categories.DUNDRUFF,
            categories.HAIR_LOSE,
        ],
    },
    {
        id: 16,
        key: 'body_sculpting',
        image: bodySculpting,
        imageBig: bodySculptingBig,
        categories: [
            categories.PUFFINESS,
            categories.CELLULITE,
        ],
    },
    {
        id: 17,
        key: 'laser_therapy',
        image: LaserTherapy,
        imageBig: LaserTherapyBig,
        categories: [
            categories.HAIR_REMOVAL
        ],
    },
    {
        id: 18,
        key: 'podology',
        image: podology,
        imageBig: podologyBig,
        categories: [
        ],
    },
    {
        id: 19,
        key: 'permanent_makeup',
        image: permanentMakeup,
        imageBig: permanentMakeupBig,
        categories: [

        ],
    },
    {
        id: 20,
        key: 'hyperhidrosis',
        image: hyperhidrosis,
        imageBig: hyperhidrosisBig,
        categories: [
        ],
    }
]
