import aquapure from '../../assets/devices/aquapure.png'
import mediostarMonolith from '../../assets/devices/mediostar-monolith.png'
import endosphere from '../../assets/devices/endosphere.png'
import dermadropTda from '../../assets/devices/dermadrop-tda.png'
import geneo from '../../assets/devices/geneo.png'
import icooneLaserMed from '../../assets/devices/icoone-laser-med.png'
import krioliposkulpt from '../../assets/devices/krioliposkulpt.png'
import lifteraHiFu from '../../assets/devices/liftera-hi-fu.png'
import mantisLaser from '../../assets/devices/mantis-laser.png'
import robolex from '../../assets/devices/robolex.png'
import ulfit from '../../assets/devices/ulfit.png'
import rfVivace from '../../assets/devices/rf-vivace.png'

export const devices = [
    {
        id: 1,
        key: 'endosphere',
        image: endosphere
    },
    {
        id: 2,
        key: 'mediostar_monolith',
        image: mediostarMonolith
    },
    {
        id: 3,
        key: 'liftera',
        image: lifteraHiFu
    },
    {
        id: 4,
        key: 'aquapure',
        image: aquapure
    },
    {
        id: 5,
        key: 'krioliposkulpt',
        image: krioliposkulpt
    },
    {
        id: 6,
        key: 'dermadrop_tda',
        image: dermadropTda,
    },
    {
        id: 7,
        key: 'geneo',
        image: geneo
    },
    {
        id: 8,
        key: 'mantis_laser',
        image: mantisLaser
    },
    {
        id: 9,
        key: 'robolex',
        image: robolex
    },
    {
        id: 10,
        key: 'ulfit',
        image: ulfit
    },
    {
        id: 11,
        key: 'rf_vivace',
        image: rfVivace
    },
    {
        id: 12,
        key: 'icoone_laser_med',
        image: icooneLaserMed
    }
]
