import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import Banner from '../../shared/components/banner';
import brandsPageBanner from '../../assets/banners/brands-page-banner.jpg';
import brandsPageBannerMobile from '../../assets/banners/brands-page-banner-mobile.jpg';
import brandsImg1 from '../../assets/brands/brands-page-img-1.jpg';
import brandsImg2 from '../../assets/brands/brands-page-img-2.jpg';
import brandsImg3 from '../../assets/brands/brands-page-img-3.jpg';
import brandsImg1xs from '../../assets/brands/brands-page-img-1xs.png';
import brandsImg2xs from '../../assets/brands/brands-page-img-2xs.png';
import brandsImg3xs from '../../assets/brands/brands-page-img-3xs.png';

import tokioInkarami from '../../assets/brands/tokio-inkarami.jpg';
import medik8 from '../../assets/brands/medik8.jpg';
import isClinical from '../../assets/brands/is-clinical.jpg';
import hydroPeptide from '../../assets/brands/hydro-peptide.jpg';
import zoSkinCentre from '../../assets/brands/zo-scin-centre.jpg';
import clinique from '../../assets/brands/clinique.jpg';
import olaplex from '../../assets/brands/olaplex.jpg';
import rosyDrop from '../../assets/brands/rosy-drop.jpg';
import rareParis from '../../assets/brands/rare-paris.jpg';
import brae from '../../assets/brands/brae.jpg';
import balmain from '../../assets/brands/balmain.jpg';
import lebel from '../../assets/brands/lebel.jpg';
import redken from '../../assets/brands/redken.jpg';
import rising from '../../assets/brands/rising.jpg';
import oribe from '../../assets/brands/oribe.jpg';
import biostetique from '../../assets/brands/biostetique.jpg';
import jamesRead from '../../assets/brands/james-read.jpg';
import kevinMurphey from '../../assets/brands/kevin-murphey.jpg';
import Button from '../../shared/components/button';
import {bookVisitLink} from '../../constants';
import {ResizeContext} from "../../context/resize-context";
import {Helmet} from "react-helmet-async";

export const Brands = () => {
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    return (
        <Page>
            <Helmet>
                <title>{t('brands.title')} | Klinika urody w Warszawie, Polsce</title>
            </Helmet>
            <Banner image={brandsPageBanner}
                    imageXs={brandsPageBannerMobile}>
                {t('brands.title')}
            </Banner>
            <div className="brands-page-content">
                <div className="brands-top-img-block">
                    {isTablet ? (
                        <>
                            <img className="brands-top-img" src={brandsImg1xs} alt={brandsImg1xs}/>
                            <img className="brands-top-img" src={brandsImg2xs} alt={brandsImg2xs}/>
                            <img className="brands-top-img" src={brandsImg3xs} alt={brandsImg3xs}/>
                        </>
                    ) : (
                        <>
                            <img className="brands-top-img" src={brandsImg1} alt={brandsImg1}/>
                            <img className="brands-top-img" src={brandsImg2} alt={brandsImg2}/>
                            <img className="brands-top-img" src={brandsImg3} alt={brandsImg3}/>
                        </>
                    )}
                </div>

                <div className="brands-list-wrapper full-width-content">
                    <div className="brands-list-grid page-container">
                        <img className="brands-list-grid-item" src={tokioInkarami} alt={tokioInkarami}/>
                        <img className="brands-list-grid-item" src={medik8} alt={medik8}/>
                        <img className="brands-list-grid-item" src={isClinical} alt={isClinical}/>
                        <img className="brands-list-grid-item" src={hydroPeptide} alt={hydroPeptide}/>
                        <img className="brands-list-grid-item" src={zoSkinCentre} alt={zoSkinCentre}/>
                        <img className="brands-list-grid-item" src={clinique} alt={clinique}/>
                        <img className="brands-list-grid-item" src={olaplex} alt={olaplex}/>
                        <img className="brands-list-grid-item" src={redken} alt={redken}/>
                        <img className="brands-list-grid-item" src={rosyDrop} alt={rosyDrop}/>
                        <img className="brands-list-grid-item" src={rareParis} alt={rareParis}/>
                        <img className="brands-list-grid-item" src={brae} alt={brae}/>
                        <img className="brands-list-grid-item" src={balmain} alt={balmain}/>
                        <img className="brands-list-grid-item" src={lebel} alt={lebel}/>
                        <img className="brands-list-grid-item" src={rising} alt={rising}/>
                        <img className="brands-list-grid-item" src={oribe} alt={oribe}/>
                        <img className="brands-list-grid-item" src={biostetique} alt={biostetique}/>
                        <img className="brands-list-grid-item" src={jamesRead} alt={jamesRead}/>
                        <img className="brands-list-grid-item" src={kevinMurphey} alt={kevinMurphey}/>
                    </div>
                </div>
                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>
            </div>
        </Page>
    )
}