import React, { useContext, useEffect, useState } from 'react';
import { ResizeContext } from '../../context/resize-context';

export const Banner = ({image, imageXs, children}) => {
    const {isTablet} = useContext(ResizeContext);
    const [backgroundImage, setBackgroundImage] = useState(image);

    useEffect(() => {
        setBackgroundImage(isTablet ? imageXs ?? image : image);
    }, [isTablet]);
    return (
        <div className="page-banner full-width-content"
             style={{'backgroundImage': `url(${backgroundImage})`}}>
            <h1 className="page-banner">{children}</h1>
        </div>
    );
};
export default Banner;