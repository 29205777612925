export const TeamMemberCard = ({name, specialty, imageUrl, onClick}) => {
    return (
        <div onClick={onClick} className="team-member-card">
            <img className="team-member-card-img" src={imageUrl} alt={imageUrl}/>
            <div className="team-member-card-name">{name}</div>
            <div className="team-member-card-specialty">{specialty}</div>
        </div>
    );
};
export default TeamMemberCard;
