import React from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import ScrollToTop from '../context/scroll-to-top';

export const Page = ({children}) => (
    <>
        <ScrollToTop>
            <Header/>
            <main className="main page-container">
                {children}
            </main>
            <Footer/>
        </ScrollToTop>
    </>
);
