import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Page} from "../../components/page";
import Banner from "../../shared/components/banner";
import teamBanner from "../../assets/banners/team-page-banner.jpg";
import teamBannerMobile from "../../assets/banners/team-page-banner-mobile.jpg";
import TeamCard from "../../shared/components/team-card";
import Button from "../../shared/components/button";
import {team} from "../../shared/configs/team.config";
import {bookVisitLink} from "../../constants";
import {useNavigate} from "react-router-dom";
import {ResizeContext} from "../../context/resize-context";
import {Helmet} from "react-helmet-async";

export const Team = () => {
    const {t} = useTranslation();
    const [teamList] = useState(team);
    const {isMobile} = useContext(ResizeContext);

    const navigate = useNavigate();
    const onTeamCardClick = (key) => {
        navigate(`/team/${key}`)
    };

    return (
        <Page>
            <Helmet>
                <title>{t('team.title')}</title>
            </Helmet>
            <Banner image={teamBanner}
                    imageXs={teamBannerMobile}>
                {t('team.title')}
            </Banner>

            <section className="page-team-section">
                <div className="team-section-title-block">
                    {!isMobile &&
                    <p className="section-description">
                        {t('team.description')}
                    </p>
                    }
                </div>
                <div className="team-container">
                    <>
                        {teamList?.map((member, index) => (
                            <TeamCard
                                onClick={() => onTeamCardClick(member.key)}
                                key={member.key || index}
                                            imageUrl={member.image}
                                            name={t(`team.${member.key}.name`)}
                                            specialty={t(`team.${member.key}.specialty`)}
                            />
                        ))}
                    </>
                </div>
                <div className="row book-visit-btn">
                    <Button onClick={() => window.open(bookVisitLink)}>
                        {t('common.book_visit')}
                    </Button>
                </div>
            </section>
        </Page>
    )
}