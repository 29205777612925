import {useEffect, useRef, useState} from "react";

export function useIntersectObserve(
    elementRef,
    options = {
        threshold: 0,
        root: null,
        rootMargin: '0%',
        freezeOnceVisible: false,
    }
){
    const [entry, setEntry] = useState();

    const frozen = entry?.isIntersecting && options.freezeOnceVisible

    const updateEntry = ([entry]) => {
        setEntry(entry)
    }
    const normalizedTrashHold = JSON.stringify(options.threshold);

    useEffect(() => {
        const node = elementRef?.current
        const hasIOSupport = !!window.IntersectionObserver

        if (!hasIOSupport || frozen || !node) return

        const observer = new IntersectionObserver(updateEntry, options)

        observer.observe(node)

        return () => observer.disconnect()

    }, [elementRef, normalizedTrashHold, options.root, options.rootMargin, frozen, options])

    return entry
}

export const useOutsideClick = (callback) => {
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback]);

    return ref;
};