import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../hooks";
import close from "../assets/icons/close.svg"
import arrow from "../assets/icons/dropdown.svg"
import { ResizeContext } from '../context/resize-context';

export const LanguageEnum = {
    EN: 'en',
    PL: 'pl',
}

export const LanguageSelector = ({shortName}) => {
    const {i18n} = useTranslation();
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    const [isOpen, setIsOpen] = useState(false);

    const currentLanguageCode = i18n.resolvedLanguage;
    const ref = useOutsideClick(() => {
        setIsOpen(false);
    });

    const handleOptionClick = (option) => {
        i18n.changeLanguage(option);
        setIsOpen(false);
    };

    return (
        <>
            <div className="language-selector" ref={ref}>
                <div className={`selected-language ${isOpen && 'selected-language-context-opened'}`}
                     onClick={() => setIsOpen(!isOpen)}>
                    {t(shortName ? `common.languages_short.${currentLanguageCode}` : `common.languages.${currentLanguageCode}`)}

                    {isOpen ?
                        <img className="close-icon" src={close} alt={close}/>:
                        <img className="close-icon" src={arrow} alt={arrow}/>
                    }
                </div>

                {isOpen && (<div className="language-selector-context-container">
                    {currentLanguageCode !== LanguageEnum.EN &&
                        <div
                            className={`language-selector-item ${i18n.resolvedLanguage === LanguageEnum.EN ? 'active' : ''}`}
                            onClick={() => handleOptionClick(LanguageEnum.EN)}>
                            {t(shortName ? `common.languages_short.${LanguageEnum.EN}` : `common.languages.${LanguageEnum.EN}`)}
                        </div>
                    }

                    {currentLanguageCode !== LanguageEnum.PL &&
                        <div
                            className={`language-selector-item ${i18n.resolvedLanguage === LanguageEnum.PL ? 'active' : ''}`}
                            onClick={() => handleOptionClick(LanguageEnum.PL)}>
                            {t(shortName ? `common.languages_short.${LanguageEnum.PL}` : `common.languages.${LanguageEnum.PL}`)}
                        </div>
                    }
                </div>)}
            </div>
        </>
    )
};
