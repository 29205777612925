import { useEffect, useMemo, useState } from 'react';
import { categories, services } from '../shared/configs/services.config';

const useServiceManager = ({isTablet}) => {
    const [data, setData] = useState(services);
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('all');

    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        const allServicesOption = 'all';
        const filters = [allServicesOption, ...Object.values(categories)];
        setFilters(filters);
    }, []);

    const handleNext = () => {
        const nextIndex = (startIndex + 1) % filters.length;
        setStartIndex(nextIndex);
    };

    const handlePrev = () => {
        const prevIndex = (startIndex - 1 + filters.length) % filters.length;
        setStartIndex(prevIndex);
    };

    const filteredServices = useMemo(() => {
        if (!selectedFilter || selectedFilter === 'all') {
            return data;
        }
        return data.filter((item) => item.categories.includes(selectedFilter));
    }, [selectedFilter, data]);

    return {
        filters,
        filteredServices,
        activeFilter: selectedFilter,
        setSelectedFilter,
        handlePrev,
        handleNext,
        startIndex
    }
}
export default useServiceManager;