import Button from '../../../shared/components/button';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ResizeContext } from '../../../context/resize-context';
import { Slide } from 'react-slideshow-image';
import CosmetologyDeviceCard from '../../../shared/components/cosmetology-device-card';
import { devices } from '../../../shared/configs/cosmetology-devices.config';
import sliderArrowLeft from '../../../assets/icons/arrow-left.svg';
import '../../../styles/components/cosmetology-devices.css'

const responsiveSettings = [
    {
        breakpoint: 991,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];
export const Devices = () => {
    const {t} = useTranslation();
    const {width, isMobile} = useContext(ResizeContext);
    const [deviceList, setDeviceList] = useState(devices);
    const [mobileDeviceList, setMobileDeviceList] = useState(devices.slice(0, 3));

    const navigate = useNavigate();
    const onDeviceCardClick = (key) => {
        navigate(`/device/${key}`)
    };
    const customArrowPreviewComponent = <div className='slider-navigation'><img src={sliderArrowLeft} alt="chevron"/>
    </div>;

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }

    return (
        <section className="main-device-section">
            <div className="devices-section-title-block">
                <a onClick={() => navigate('/devices')}
                   className="section-title">
                    <h2 className="section-title">{t('devices.title')}</h2>
                </a>
                <p className="section-description">
                    {t('devices.description')}
                </p>
            </div>
            <div className="devices-slider-container">
                {width < 768 ? (
                    <>
                        {mobileDeviceList?.map((device, index) => (
                            <CosmetologyDeviceCard key={device.key || index}
                                                   onClick={() => onDeviceCardClick(device.key)}
                                                   imageUrl={device.image}
                                                   title={t(`devices.${device.key}.title`)}
                                                   caption={t(`devices.${device.key}.caption`)}
                            />
                        ))}
                    </>
                ) : (<Slide autoplay={false} responsive={responsiveSettings} {...properties}>
                        {deviceList?.map((device, index) => (
                            <CosmetologyDeviceCard key={device.key || index}
                                                   onClick={() => onDeviceCardClick(device.key)}
                                                   imageUrl={device.image}
                                                   title={t(`devices.${device.key}.title`)}
                                                   caption={t(`devices.${device.key}.caption`)}
                            />
                        ))}
                    </Slide>
                )}
            </div>

            <div className="row">
                <Button onClick={() => navigate('/devices')}>
                    {t('services.see_all_btn')}
                </Button>
            </div>
        </section>
    )
}