import React, {useContext,useState } from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {ResizeContext} from '../../../context/resize-context';
import brands from '../../../assets/brands/brands-placeholder.svg'
import brandsmobile from '../../../assets/brands/brands-placeholder-mobile.svg'
import '../../../styles/components/brands.css'
import Button from '../../../shared/components/button';


export const Brands = () => {
    const {t} = useTranslation();
    const {isTablet} = useContext(ResizeContext);

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(true);
    };
    const navigate = useNavigate();

    return (
        <section className="main-brands-section full-width-content">
            <div className="page-container brands">
                <div className="brands-info">
                    <a onClick={() => navigate('/brands')}
                       className="section-title">
                        <h2 className="section-title">{t('brands.title')}</h2>
                    </a>
                    <p className="brands-description">
                        {t('brands.section_description1')}
                    </p>
                    {isExpanded && (
                    <p className="brands-description">
                        {t('brands.section_description2')}
                    </p>
                    )}
                    {!isExpanded && (
                        <button onClick={toggleReadMore} className="brands-description-read-btn">Read more</button>
                    )}
                    <div className="brands-logos">
                        <i className="logo"></i>
                        <i className="logo"></i>
                        <i className="logo"></i>
                    </div>
                    {!isTablet &&
                    <Button onClick={() => navigate('/brands')}>
                        {t('common.read_more')}
                    </Button>
                    }
                </div>
                {isTablet ? (
                    <img src={brandsmobile} alt={brandsmobile}/>
                ) : (
                    <img src={brands} alt={brands}/>
                )}
                {isTablet &&
                <div className="read-more-btn">
                    <Button buttonType={"testbrand"} onClick={() => navigate('/brands')}>
                        {t('common.read_more')}
                    </Button>
                </div>
                }
            </div>

        </section>
    )
}