import imgmob1pl from '../../assets/clinic/main-slider-img-mob-1pl.jpg';
import imgmob2pl from '../../assets/clinic/main-slider-img-mob-2pl.jpg';
import imgmob3pl from '../../assets/clinic/main-slider-img-mob-3pl.jpg';

import slider1pl from '../../assets/clinic/main-slider-img-1pl.jpg';
import slider2pl from '../../assets/clinic/main-slider-img-2pl.jpg';
import slider3pl from '../../assets/clinic/main-slider-img-3pl.jpg';

import imgbig1pl from '../../assets/clinic/main-slider-img-1pl.svg';
import imgbig2pl from '../../assets/clinic/main-slider-img-2pl.svg';
import imgbig3pl from '../../assets/clinic/main-slider-img-3pl.svg';

import imgmob1 from '../../assets/clinic/main-slider-img-mob-1.jpg';
import imgmob2 from '../../assets/clinic/main-slider-img-mob-2.jpg';
import imgmob3 from '../../assets/clinic/main-slider-img-mob-3.jpg';

import slider1 from '../../assets/clinic/main-slider-img-1.jpg';
import slider2 from '../../assets/clinic/main-slider-img-2.jpg';
import slider3 from '../../assets/clinic/main-slider-img-3.jpg';

import imgbig1 from '../../assets/clinic/main-slider-img-1.svg';
import imgbig2 from '../../assets/clinic/main-slider-img-2.svg';
import imgbig3 from '../../assets/clinic/main-slider-img-3.svg';

export const slideConfig = [
    {
        id: 'promo',
        imgmobpl: imgmob1pl,
        imgmob: imgmob1,
        filenamepl: slider1pl,
        filename: slider1,
        imgbigpl: imgbig1pl,
        imgbig: imgbig1,
        caption: '30% promo'
    },
    {
        id: 'promo2',
        imgmobpl: imgmob2pl,
        imgmob: imgmob2,
        filenamepl: slider2pl,
        filename: slider2,
        imgbigpl: imgbig2pl,
        imgbig: imgbig2,
        caption: '30% promo'
    },
    {
        id: 'promo3',
        imgmobpl: imgmob3pl,
        imgmob: imgmob3,
        filenamepl: slider3pl,
        filename: slider3,
        imgbigpl: imgbig3pl,
        imgbig: imgbig3,
        caption: '30% promo'
    },
]