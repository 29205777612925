import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({  children }) => {
    const location = useLocation();

    useEffect(() => {
        window.window.scrollTo({left:0, top:0, behavior: 'instant'});
    }, [location.pathname]);

    return children;
}

export default ScrollToTop;
