import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import {Page} from "../../components/page";

import '../../styles/components/cosmetology-device-item.css'

import {useNavigate, useParams} from "react-router-dom";
import {devices} from "../../shared/configs/cosmetology-devices.config";
import Button from "../../shared/components/button";
import {bookVisitLink} from "../../constants";
import {ResizeContext} from "../../context/resize-context";

export const CosmetologyDeviceItem = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {key} = useParams();
    const {isTablet} = useContext(ResizeContext);

    const [device, setDevice] = useState(null);

    const [showMore, setShowMore] = useState(false);

    const handleToggle = () => {
        setShowMore(!showMore);
    };

    useEffect(() => {
        const foundDevice = devices.find(el => el.key === key);
        setDevice(foundDevice);
    }, [key]);

    return (
        <Page>
            <div className="cosmetology-device-item-page full-width-content">
                {isTablet ? (
                    <>
                        <div className="cosmetology-left-column">
                            {device ? (
                                    <>
                                <p className="cosmetology--item-des">{t(`devices.${device.key}.caption`)}</p>
                                <h2 className="cosmetology--item-title">{t(`devices.${device.key}.title`)}</h2>
                                <img src={device.image} alt="Description of Image"/>
                                    </>
                                ) : (
                              <p>error</p>  // navigate('/404', {replace: true})
                                )}

                        </div>
                        <div className="cosmetology-right-column">

                            {device ? (
                                <>
                                    <div className="page-container team-des-offered">
                                        <div className="team-list-grid ">
                                            <div className="team-list-grid-item">
                                                {device ? (
                                                    <>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: t(`devices.${device.key}.des`) }}
                                                        />

                                                        {showMore && (
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: t(`devices.${device.key}.des2`) }}
                                                            />
                                                        )}

                                                        {!showMore && t(`devices.${device.key}.des2`) !== `devices.${device.key}.des2` && (
                                                            <a className="device-read-more" onClick={handleToggle}>{t('common.read_more')}</a>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p>345</p>
                                                )}
                                                <div className="device-item-page-btn">
                                                    <div className="row book-visit-btn">
                                                        <Button onClick={() => window.open(bookVisitLink)}>
                                                            {t('common.book_visit')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                </>

                            ) : (
                                <p>234</p> // navigate('/404', {replace: true})
                            )}

                        </div>

                    </>
                ) : (
                    <>

                        <div className="cosmetology-right-column">

                            {device ? (
                                <>
                                    <p className="cosmetology--item-des">{t(`devices.${device.key}.caption`)}</p>
                                    <h2 className="cosmetology--item-title">{t(`devices.${device.key}.title`)}</h2>

                                </>
                            ) : (
                                <h1>456</h1> // navigate('/404', {replace: true})
                            )}
                            <div className="device-item-page-btn">
                                <div className="row book-visit-btn">
                                    <Button onClick={() => window.open(bookVisitLink)}>
                                        {t('common.book_visit')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="cosmetology-left-column">
                            {device ? (
                                <img src={device.image} alt="Description of Image"/>
                            ) : (
                                <p>345</p>  // navigate('/404', {replace: true})
                            )}

                        </div>

                    </>

                )}

            </div>
            {isTablet ? ( "") : (
            <div className="page-container team-des-offered">
                <div className="team-list-grid ">
                    <div className="team-list-grid-item">
                        {device ? (
                            <>
                                <div
                                    dangerouslySetInnerHTML={{ __html: t(`devices.${device.key}.des`) }}
                                />

                                {showMore && (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: t(`devices.${device.key}.des2`) }}
                                    />
                                )}

                                {!showMore && t(`devices.${device.key}.des2`) !== `devices.${device.key}.des2` && (
                                    <a className="device-read-more" onClick={handleToggle}>{t('common.read_more')}</a>
                                )}
                            </>
                        ) : (
                            <p>345</p>
                        )}

                    </div>

                </div>
            </div>
            )}

        </Page>
    )
}