import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import { useTranslation } from "react-i18next";
import 'react-slideshow-image/dist/styles.css';
import { ResizeContext } from '../../../context/resize-context';
import sliderArrowIcon from '../../../assets/clinic/slider-arrow.svg';

import { slideConfig } from '../../../shared/configs/promo-slider.config';
import '../../../styles/components/promo-slider.css'
import Button from '../../../shared/components/button';
import dailyImgXs2 from "../../../assets/about-us/img-daily-xs-2.jpg";
import dailyImg2 from "../../../assets/about-us/img-daily-2.jpg";
import {bookVisitLink} from "../../../constants";


export const PromoSlider = () => {
    const {t, i18n} = useTranslation();
    const {isMobile,isTablet,isDesktop,isDesktopHd} = useContext(ResizeContext);
    const [isShortApparatusList, setShortApparatusList] = useState(false);
    const slideOptions = useMemo(() => isShortApparatusList ? slideConfig.slice(0, 3) : slideConfig, [isShortApparatusList]);

    useEffect(() => {
        setShortApparatusList(isTablet)
    }, [isTablet]);

    const customArrowPreviewComponent = <div className='slider-navigation'><img src={sliderArrowIcon} alt="chevron"/>
    </div>;

    const properties = {
        prevArrow: customArrowPreviewComponent,
        nextArrow: customArrowPreviewComponent,
    }


    const indicators = () => (<div className="indicator"></div>);

    return (
        <section className="promo-slider full-width-content">
            <>
                <div className={`promo-slider-container ${isTablet && 'full-width-content'}`}>
                    <Slide indicators={indicators} {...properties}>
                        {slideOptions.map((apparatus, index) => (
                            <div key={index}>
                                <div className="promo-slider-content"
                                     style={{'backgroundImage': `url(${isMobile
                                             ? (i18n.language === 'pl'
                                                     ? apparatus.imgmobpl
                                                     : (apparatus.imgmob ?? apparatus.filenamepl)
                                             )
                                             : (i18n.language === 'pl' ? apparatus.filenamepl : apparatus.filename)})`}}>
                                    <Button  onClick={() => {
                                        const isPhone = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                                        window.open(isPhone ? 'instagram://user?username=leobeautyclinic' : 'https://www.instagram.com/leobeautyclinic', '_blank');
                                    }}
                                            type="primary-button theme-button-xs learn-more-btn">
                                            {t('common.learn_more')}
                                        </Button>
                                </div>
                            </div>
                        ))}
                    </Slide>
                </div>
            </>
        </section>
    );
};